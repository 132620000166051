var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex justify-start" }, [
    _c("input", {
      directives: [
        { name: "show", rawName: "v-show", value: false, expression: "false" }
      ],
      ref: "file_upload_file_input",
      attrs: { type: "file", accept: _vm.accept },
      on: {
        change: function($event) {
          return _vm.onFileSelected($event, "favicon_url")
        }
      }
    }),
    _c("div", [
      _c(
        "div",
        [
          _vm.value || (_vm.fileFile && _vm.isManualFileLoaded)
            ? _c(
                "v-chip",
                {
                  ref: "chip",
                  staticClass: "secondary_font--text background",
                  attrs: { small: "" },
                  on: { click: _vm.triggerInput }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.value
                          ? _vm.$t("components.inputs.FileInput.change")
                          : _vm.fileFile.name
                      ) +
                      " "
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-6 secondary_font--text",
                      attrs: { "x-small": "" }
                    },
                    [_vm._v("$cp_refresh")]
                  )
                ],
                1
              )
            : _c(
                "v-chip",
                {
                  ref: "chip",
                  staticClass: "secondary_font--text background",
                  attrs: { disabled: _vm.hasError, small: "" },
                  on: { click: _vm.triggerInput }
                },
                [
                  !_vm.loading
                    ? _c(
                        "div",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("components.inputs.FileInput.select")
                              ) +
                              " "
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-6 primary--text",
                              attrs: { "x-small": "" }
                            },
                            [_vm._v("$cp_upload")]
                          )
                        ],
                        1
                      )
                    : _c("v-progress-circular", {
                        attrs: { size: "15", width: "2", indeterminate: "" }
                      })
                ],
                1
              ),
          _vm.value && !_vm.isManualFileLoaded
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { icon: "", small: "" },
                  on: { click: _vm.redirectToFile }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "secondary_font--text",
                      attrs: { small: "" }
                    },
                    [_vm._v("$cp_download")]
                  )
                ],
                1
              )
            : _vm._e(),
          (_vm.value || _vm.fileFile) && !_vm.hasError
            ? _c(
                "v-icon",
                { staticClass: "ml-2 success--text", attrs: { small: "" } },
                [_vm._v("$cp_check_circle")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.hasError
        ? _c("div", { staticClass: "d-flex" }, [
            _c("span", { staticClass: "ml-3 error--text error-message" }, [
              _vm._v(_vm._s(_vm.$t("components.inputs.FileInput.error")))
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }