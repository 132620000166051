<template>
    <div class="d-flex justify-start">
        <input
            type="file" ref="file_upload_file_input"
            :accept="accept" v-show="false"
            @change="onFileSelected($event, 'favicon_url')"
        />
        <div>
            <div>
                <v-chip
                    ref="chip"
                    v-if="value || (fileFile && isManualFileLoaded)"
                    @click="triggerInput"
                    small class="secondary_font--text background"
                >
                    {{ value ? $t('components.inputs.FileInput.change') : fileFile.name }}
                    <v-icon x-small class="ml-6 secondary_font--text">$cp_refresh</v-icon>
                </v-chip>
                <v-chip
                    ref="chip"
                    v-else
                    @click="triggerInput"
                    :disabled="hasError"
                    small class="secondary_font--text background"
                >
                    <div v-if="!loading">
                        {{ $t('components.inputs.FileInput.select') }}
                        <v-icon x-small class="ml-6 primary--text">$cp_upload</v-icon>
                    </div>
                    <v-progress-circular v-else size="15" width="2" indeterminate/>
                </v-chip>
                <v-btn v-if="value && !isManualFileLoaded" @click="redirectToFile" icon small class="ml-2">
                    <v-icon small class="secondary_font--text">$cp_download</v-icon>
                </v-btn>
                <v-icon v-if="(value || fileFile) && !hasError" small class="ml-2 success--text">$cp_check_circle</v-icon>
            </div>
            <div v-if="hasError" class="d-flex">
                <span class="ml-3 error--text error-message">{{ $t('components.inputs.FileInput.error') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import cleanAxios           from 'axios'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    name: 'FileInput',
    props: {
        value: String,
        type: String,
        accept: {
            type: String,
            default: () => 'application/pdf',
        },

        postUpload: Function,
        params: {
            type: Object,
            default: () => ({}),
        },

        maxSizeMB: {
            default: () => 10,
        },
        manual: Boolean,
    },
    data: vm => ({
        loading               : false,
        hasError              : false,
        isManualFileLoaded    : false,
        fileResult            : null,
        fileFile              : null,
        currentFileWasUploaded: false,   // evita que envie o mesmo arquivo 2 vezes
    }),
    mixins: [ HasErrorHandlerMixin ],
    methods: {
        redirectToFile() {
            if (this.value)
                window.open(this.value, '_blank')
        },
        triggerInput() {
            if (!this.hasError)
                this.$refs.file_upload_file_input.click()
        },
        async onFileSelected(event, attributeName) {
            if (this.loading)
                return

            this.currentFileWasUploaded = false

            const files = event.target.files

            if (files[0] !== undefined) {
                let fileName = files[0].name
                if (fileName.lastIndexOf('.') <= 0) {
                    return
                }

                this.loading = true

                // Valida o tamanho do arquivo
                if (files[0].size / 1048576 > this.maxSizeMB) {
                    this.loading = false
                    await new Promise((resolve) => {
                        this.$bus.$emit('alert', this.$t(`components.inputs.FileInput.size_error`, { max: this.maxSizeMB }), 'error', resolve)
                    })
                    return
                }

                const fr = new FileReader
                fr.readAsDataURL(files[0])
                fr.addEventListener('load', async () => {
                    this.fileResult = fr.result
                    this.fileFile   = files[0]   // this is an image file that can be sent to server...

                    // this.$emit('input', fr.result)
                    this.loading = false
                    if (!this.manual) {
                        this.requestFileUpload()
                    } else {
                        this.isManualFileLoaded = true
                    }
                })
            }
        },

        async requestFileUpload() {
            this.loading = true
            let result   = this.fileResult
            let file     = this.fileFile

            if (!result || !file || this.currentFileWasUploaded) {
                this.loading = false
                return
            }

            let res = await this.$axios.post('/file/request', { action: this.type, file_type: file.type, params: this.params })
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            this.loading = true
            let postUploadConfig = res.data

            // Cria todos os campos indicados pelo createPresignedPost() no backend
            const formData = new FormData()
            formData.append('acl', 'public-read')
            formData.append('Content-Type', file.type)
            Object.entries(postUploadConfig.fields).forEach(([k, v]) => {
                formData.append(k, v)
            })
            formData.append('file', file) // must be the last one

            res = await cleanAxios({
                url   : postUploadConfig.url,
                method: 'POST',
                data  : formData,
            })
            .catch(this.preErrorHandler)

            if (!res)
                return

            // O timestamp garante que irá ser feito o refresh
            let newUrl = `${postUploadConfig.url}/${postUploadConfig.fields.key}?${Date.now()}`

            // Atualiza o caminho para a imagem no modelo
            this.$emit('input',  newUrl)
            this.$emit('upload', file, newUrl, res)

            // Se precisa realizar algo depois do upload
            if (typeof this.postUpload === 'function') {
                await this.postUpload(newUrl)
                    .catch(this.preErrorHandler)
            }

            this.$bus.$emit('message', this.$t('components.inputs.FileInput.success'), 'success')
            this.loading = false

            this.currentFileWasUploaded = true
        },

        preErrorHandler(e) {
            this.loading = false
            let status   = this.$lodash.get(e, 'response.status')
            let data     = this.$lodash.get(e, 'response.data')

            // Verifica se é algum erro previsto do S3
            if (status == 400) {
                let xmlDoc
                if (window.DOMParser) {
                    let parser = new DOMParser()
                    if (data)
                        xmlDoc = parser.parseFromString(data, 'text/xml')
                }
    
                let code = xmlDoc ? xmlDoc.getElementsByTagName('Code')[0].childNodes[0].nodeValue : null
                if (code == 'EntityTooLarge') {
                    let MaxSizeAllowed = xmlDoc.getElementsByTagName('MaxSizeAllowed')[0].childNodes[0].nodeValue
                    let maxInMB        = this.$lodash.round(MaxSizeAllowed / 1000000, 2)
                    this.$bus.$emit('message', this.$t('components.inputs.FileInput.size_error', { max: maxInMB }), 'warning')
                    return
                }
            }

            // Se não é um erro previsto, sobe para o errorHandler geral
            this.hasError = true
            this.errorHandler(e)
        },
    },
}
</script>

<style scoped lang="scss">
.error-message {
    font-size: 12px;
}
</style>